<template>
  <div class="wrapper-blog">
    <SubheaderBlog />
    <main class="pad-3 bg-blog">
      <div class="bb-container">
        <h1 class="header-1">BlogBB, el blog de HelloBB</h1>
        <h2 class="header-2">Lo más destacado</h2>
        <div class="g-post">
          <div class="g-post__items">
            <BlogPostThumb
              :post="featuredPost"
              class="thumb-post--land thumb-post--hero"
              showExcerpt
            />
            <BlogPostThumb
              v-for="featured2Post in featured2Posts"
              :key="featured2Post.id"
              :post="featured2Post"
              class="thumb-post--land"
            />
          </div>
        </div>
        <h2 class="header-2">Productos</h2>
        <div class="g-post">
          <div class="g-post__items">
            <BlogPostThumb
              v-for="productPost in productPosts"
              :key="productPost.id"
              :post="productPost"
              class=""
            />
          </div>
          <div class="g-post__actions">
            <a href="/blog/categorias/productos" class="button button--dark-outline"
              >Ver todo sobre productos</a
            >
          </div>
        </div>

        <div class="mar-t-3 mar-b-4">
          <div class="cta-lila">
            <h2 class="header-2 color-white">
              ¿No sabes por dónde empezar a hacer tu lista?
            </h2>
            <div class="mar-b-2">
              No te pierdas nuestras listas de ejemplo que se adaptan a cada situación
            </div>
            <a
              class="button button--primary"
              alt="Ver listas de Ejemplo"
              :href="'/blog/categorias/listas-de-ejemplo' + appQueryParam"
              >Ver listas de ejemplo</a
            >
          </div>
        </div>

        <h2 class="header-2">Maternidad</h2>
        <div class="g-post">
          <div class="g-post__items">
            <BlogPostThumb
              v-for="maternityPost in maternityPosts"
              :key="maternityPost.id"
              :post="maternityPost"
              class=""
            />
          </div>
          <div class="g-post__actions">
            <a
              :href="'/blog/categorias/maternidad' + appQueryParam"
              class="button button--dark-outline"
              >Ver todo sobre maternidad</a
            >
          </div>
        </div>

        <div class="mar-t-3 mar-b-4">
          <div class="cta-bubles">
            <div class="cta-bubles__content">
              <h2 class="header-2">
                Se acerca ese día tan esperado... <br />
                ¿Ya lo tienes todo a punto?
              </h2>
              <a
                class="button button--accent"
                alt="Ver listas de ejemplo"
                :href="'/blog/categorias/listas-de-ejemplo' + appQueryParam"
                >Ver listas de ejemplo</a
              >
            </div>
            <div class="cta-bubles__cover">
              <img
                src="../../assets/img/covers/cover-cta-icons.svg"
                alt=""
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <h2 class="header-2">Listas de ejemplo</h2>
        <div class="g-post">
          <div class="g-post__items">
            <BlogPostThumb
              v-for="exampleListsPost in exampleListsPosts"
              :key="exampleListsPost.id"
              :post="exampleListsPost"
              class=""
            />
          </div>
          <div class="g-post__actions">
            <a
              :href="'/blog/categorias/listas-de-ejemplo' + appQueryParam"
              class="button button--dark-outline"
              >Ver todo sobre listas de ejemplo</a
            >
          </div>
          <!--          Sergi: Prova per tenir un directori de tots els articles i millorar SEO, hi hagui navegació per arribar a tots els articles -> Via categories és possible-->
          <!--          <div class="g-post__actions mt-4">-->
          <!--            <a-->
          <!--                href="/blog/posts"-->
          <!--                class="button button&#45;&#45;dark-outline"-->
          <!--            >Todos los artículos</a-->
          <!--            >-->
          <!--          </div>-->
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import BlogPostThumb from "./components/BlogPostThumb.vue";
import { mapGetters } from "vuex";
import SubheaderBlog from "./SubheaderBlog.vue";

export default {
  name: "Blog",
  components: {
    BlogPostThumb,
    SubheaderBlog,
  },
  metaInfo() {
    return {
      title: `BlogBB | Lo que necesitas saber para prepararte para tu bebé`,
      meta: [
        {
          name: "description",
          content:
            "Inspiración y respuestas para mamás y papás primerizos. Descubre todo lo que necesitas saber y tener para hacer más fácil tu camino a la maternidad.",
        },
      ],
      link: [{ rel: "canonical", href: "https://www.hellobb.net/blog" }],
      script: [
        {
          type: "application/ld+json",
          json: {
            "@context": "https://schema.org",
            "@type": "WebSite",
            publisher: {
              "@type": "Organization",
              name: "HelloBB Blog - Información para padres novatos",
              url: "https://www.hellobb.net/blog",
              logo: {
                "@type": "ImageObject",
                url: "https://www.hellobb.net/logo-hellobb-dark.svg",
                width: 48,
                height: 48,
              },
            },
            url: "https://www.hellobb.net/blog",
            image: {
              "@type": "ImageObject",
              url: "https://www.hellobb.net/hbb-card.jpg",
              width: 1200,
              height: 630,
            },
            mainEntityOfPage: {
              "@type": "WebPage",
              "@id": "https://www.hellobb.net/blog",
            },
            description:
              "Guías, información sobre productos y trucos que harán tu camino a la maternidad más fácil.",
          },
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      featuredPost: "featuredPost",
      featured2Posts: "featured2Posts",
      productPosts: "productPosts",
      maternityPosts: "maternityPosts",
      exampleListsPosts: "exampleListsPosts",
    }),
    appQueryParam() {
      if (this.$store.state.cookies.sourceApp == "true") return "?source=app";
      return "";
    },
  },
  async serverPrefetch() {
    await this.$store.dispatch("getFeaturedPost");
    await this.$store.dispatch("getFeatured2Posts");
    await this.$store.dispatch("getProductPosts");
    await this.$store.dispatch("getMaternityPosts");
    await this.$store.dispatch("getExampleListsPosts");
  },
};
</script>
<style lang=""></style>
